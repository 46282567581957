import { createSlice } from "@reduxjs/toolkit";

import { ProductData } from '../data/productsData'

// Product Slice
const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: ProductData,
        carts: ProductData.slice(4, 7),
        favorites: ProductData.slice(8, 12),
        compare: ProductData.slice(0, 2),
        single: null,
    },
    reducers: {

    }
})

const productsReducer = productsSlice.reducer
export default productsReducer
