import axios from 'axios';
import { create } from 'apisauce';
import { BASE_URL } from '../utils/contants';

const customAxiosInstance = axios.create({
  baseURL: BASE_URL,
});

customAxiosInstance.interceptors.request.use(
  async config => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  },
);

const client = create({ axiosInstance: customAxiosInstance });

export default { client };
