import { configureStore } from '@reduxjs/toolkit';

import userReducer from "./slices/user";
import AddressReducer from './slices/address';
import productsReducer from "./slices/products";
import settingsReducer from "./slices/settings";
import ProductListReducer from './slices/product-list';
import UIControllerReducer from './slices/ui-controller';

export const store = configureStore({
  reducer: {
    user: userReducer,
    address: AddressReducer,
    products: productsReducer,
    settings: settingsReducer,
    productList: ProductListReducer,
    uiController: UIControllerReducer
  },
});
