import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToast: false,
  toastTextMessage: "",
}

export const ProductsSlice = createSlice({
  name: "uiController",
  initialState,
  reducers: {
    setShowToast: (state, action) => {
      state.showToast = action.payload;
    },
    setToastTextMessage: (state, action) => {
      state.toastTextMessage = action.payload;
    },
  }
})

export default ProductsSlice.reducer;
export const { setShowToast, setToastTextMessage } = ProductsSlice.actions;
