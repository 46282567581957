import pMinDelay from 'p-min-delay';
import React, { useEffect } from 'react';
import Loading from './component/Common/loader';
import loadable from './component/Common/loader/loadable';
import AuthenticationPages from './navigation/authentication.routes';
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getAllShippingAddress } from './utils/address-api';
import { addProductToWishList, getAllProducts, getCartProduct, getWishListProduct } from './services/products';
import { setProductCartSubTotal, setProductCarts, setProducts, setWishList } from './app/slices/product-list';

// All Page Lazy Import
const ShopGrid = loadable(() => pMinDelay(import('./page/shop'), 250), { fallback: <Loading /> });
const ShopTwo = loadable(() => pMinDelay(import('./page/shop/shop-two'), 250), { fallback: <Loading /> });
const ShopList = loadable(() => pMinDelay(import('./page/shop/shop-list'), 250), { fallback: <Loading /> });
const ShopBook = loadable(() => pMinDelay(import('./page/shop/look-book'), 250), { fallback: <Loading /> });
const ShopRightSideBar = loadable(() => pMinDelay(import('./page/shop/shop-right-sidebar'), 250), { fallback: <Loading /> });
const ShopLeftSideBar = loadable(() => pMinDelay(import('./page/shop/shop-left-sidebar'), 250), { fallback: <Loading /> });

const ProductDetailsTwos = loadable(() => pMinDelay(import('./page/product/product-details-two'), 250), { fallback: <Loading /> });

const CartThree = loadable(() => pMinDelay(import('./page/cart/cart-three'), 250), { fallback: <Loading /> });
const EmptyCarts = loadable(() => pMinDelay(import('./page/cart/empty-cart'), 250), { fallback: <Loading /> });

const CheckoutTwos = loadable(() => pMinDelay(import('./page/checkout/checkout-two'), 250), { fallback: <Loading /> });
const WishLists = loadable(() => pMinDelay(import('./page/shop/wishList'), 250), { fallback: <Loading /> });


const ProductHover = loadable(() => pMinDelay(import('./page/product/product-hover'), 250), { fallback: <Loading /> });
const MyAccounts = loadable(() => pMinDelay(import('./page/my-account'), 250), { fallback: <Loading /> });
const CustomerOrder = loadable(() => pMinDelay(import('./page/my-account/customer-order'), 250), { fallback: <Loading /> });
const CustomerDownloads = loadable(() => pMinDelay(import('./page/my-account/customer-downloads'), 250), { fallback: <Loading /> });
const CustomerAddress = loadable(() => pMinDelay(import('./page/my-account/customer-address'), 250), { fallback: <Loading /> });
const CustomerAccountDetails = loadable(() => pMinDelay(import('./page/my-account/customer-account-details'), 250), { fallback: <Loading /> });
const AccountEdit = loadable(() => pMinDelay(import('./page/vendor/account-edit'), 250), { fallback: <Loading /> });
const Error = loadable(() => pMinDelay(import('./page/error'), 250), { fallback: <Loading /> });
const PrivacyPolicy = loadable(() => pMinDelay(import('./page/privacy-policy'), 250), { fallback: <Loading /> });
const Faqs = loadable(() => pMinDelay(import('./page/faqs'), 250), { fallback: <Loading /> });
const ComingSoon = loadable(() => pMinDelay(import('./page/coming-soon'), 250), { fallback: <Loading /> });
const ContactOne = loadable(() => pMinDelay(import('./page/contact'), 250), { fallback: <Loading /> });
const ScrollToTop = loadable(() => pMinDelay(import('./component/Common/ScrollToTop'), 250), { fallback: <Loading /> });
const Fashion = loadable(() => pMinDelay(import('./page/'), 250), { fallback: <Loading /> });
const About = loadable(() => pMinDelay(import('./page/about'), 250), { fallback: <Loading /> });
const TrackOrder = loadable(() => pMinDelay(import('./page/order/order-tracking'), 250), { fallback: <Loading /> });

const App = () => {
  const dispatch = useDispatch();
  let { loadAddress } = useSelector((state) => state.address)

  useEffect(() => {
    getUserDetail();
    fetchProductList();
    handleGetProductCartList();
    handleGetWishlistProductList();
    getAllShippingAddress(dispatch);
  }, []);

  useEffect(() => {
    if (loadAddress) {
      getAllShippingAddress(dispatch)
    }
  }, [loadAddress])

  const getUserDetail = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== undefined) {
      dispatch({ type: "user/login", payload: { user } })
    }
  }

  const fetchProductList = async () => {
    try {
      const response = await getAllProducts(1);
      if (response.ok) {
        dispatch(setProducts(response?.data?.data));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleGetProductCartList = async () => {
    try {
      const cartProductResponse = await getCartProduct();
      if (cartProductResponse.ok) {
        dispatch(setProductCarts(cartProductResponse.data.data.data));
        dispatch(setProductCartSubTotal(cartProductResponse.data.data.subTotal));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleGetWishlistProductList = async () => {
    try {
      const cartProductResponse = await getWishListProduct();
      if (cartProductResponse.ok) {
        dispatch(setWishList(cartProductResponse.data.data));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <BrowserRouter>
        <Router>
          <ScrollToTop />
          <Switch>
            {/* <Route path='/' exact component={Fashion} /> */}
            <Route path='/' exact component={ShopGrid} />
            <Route path='/shopTwo' exact component={ShopTwo} />
            <Route path='/shopList' exact component={ShopList} />
            <Route path='/checkout-two' exact component={CheckoutTwos} />


            <Route path='/product-details-two/:id' exact component={ProductDetailsTwos} />
            <Route path='/wishlist' exact component={WishLists} />
            <Route path='/product-hover' exact component={ProductHover} />
            <Route path='/cartThree' exact component={CartThree} />
            <Route path='/empty-cart' exact component={EmptyCarts} />

            {/* Account */}
            <Route path='/my-account' exact component={CustomerOrder} />
            {/* <Route path='/my-account/customer-order' exact component={CustomerOrder} /> */}
            <Route path='/my-account/customer-download' exact component={CustomerDownloads} />
            <Route path='/my-account/customer-address' exact component={CustomerAddress} />
            <Route path='/my-account/customer-account-details' exact component={CustomerAccountDetails} />
            <Route path='/account-edit' exact component={AccountEdit} />

            <Route path='/privacy-policy' exact component={PrivacyPolicy} />
            <Route path='/faqs' exact component={Faqs} />
            <Route path='/coming-soon' exact component={ComingSoon} />
            <Route path='/about' exact component={About} />
            <Route path='/contact-one' exact component={ContactOne} />
            <Route path='/order-tracking' exact component={TrackOrder} />
            <AuthenticationPages />
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
}

export default App;