import { Route } from 'react-router-dom';
import { authenticationRoutes } from './routes/authentication';

const AuthenticationPages = () => {
  return (
    <div>
      {authenticationRoutes.map((page) => (
        <Route path={page.path} exact component={page.component} />
      ))}
    </div>
  )
}

export default AuthenticationPages;