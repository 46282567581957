import { createSlice } from "@reduxjs/toolkit";

// User Slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    status: false,
    user: {}
  },
  reducers: {
    // Login
    login: (state, action) => {
      state.status = true
      state.user = action.payload
    },
    // Register
    register: (state, action) => {
      let { user, email, pass } = action.payload;
      state.status = true
      state.user = {
        name: user,
        role: 'customer',
        email: email,
        pass: pass
      }
    },
    logout: (state) => {
      state.status = false
      state.user = {}
    }
  }
})

const userReducer = userSlice.reducer
export default userReducer
