import pMinDelay from 'p-min-delay';
import Loading from '../../component/Common/loader';
import loadable from '../../component/Common/loader/loadable';

const Login = loadable(() => pMinDelay(import('../../page/login'), 250), { fallback: <Loading /> });
const Register = loadable(() => pMinDelay(import('../../page/register'), 250), { fallback: <Loading /> });
const ForgotPassword = loadable(() => pMinDelay(import('../../page/forgot'), 250), { fallback: <Loading /> });

export const authenticationRoutes = [{
  name: 'Login',
  path: '/login',
  component: Login
}, {
  name: 'Register',
  path: '/register',
  component: Register
}, {
  name: 'Forgot password',
  path: '/forgot-password',
  component: ForgotPassword
}];
