import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadAddress: false,
  shippingAddress: [],
}

export const AddressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setLoadAddress: (state, action) => {
      state.loadAddress = action.payload;
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
  }
})

export default AddressSlice.reducer;
export const { setShippingAddress, setLoadAddress } = AddressSlice.actions;
