import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  wishlist: [],
  subTotal: {},
  productCarts: []
}

export const ProductsSlice = createSlice({
  name: "productList",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setWishList: (state, action) => {
      state.wishlist = action.payload;
    },
    setProductCarts: (state, action) => {
      state.productCarts = action.payload;
    },
    setProductCartSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
  }
})

const ProductList = ProductsSlice.reducer

export default ProductList;

export const { setProducts, setWishList, setProductCarts, setProductCartSubTotal } = ProductsSlice.actions;
