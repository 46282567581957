import apiConfig from './config';

export const addShippingAddress = (payload) =>
  apiConfig.client.post(`/shipping-address`, payload);

export const shippingAddressList = (page = 1) =>
  apiConfig.client.get(`/shipping-address/page/${page}`);

export const shippingAddressById = (id) =>
  apiConfig.client.get(`/shipping-address/${id}`);

export const shippingAddressUpdateById = (id) =>
  apiConfig.client.put(`/shipping-address/${id}`);

