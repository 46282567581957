const { shippingAddressList } = require("../services/shipping-address");
const { setShippingAddress, setLoadAddress } = require("../app/slices/address");

exports.getAllShippingAddress = async (dispatch) => {
  try {
    const response = await shippingAddressList();
    if (response.ok) {
      dispatch(setLoadAddress(false));
      dispatch(setShippingAddress(response?.data?.data?.data))
    }
  } catch (error) {
    console.log(error.message);
  }
}
