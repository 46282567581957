import apiConfig from './config';

export const getAllProducts = (page) =>
  apiConfig.client.get(`/products/page/${page}`);

export const productGetById = (id) =>
  apiConfig.client.get(`/products/${id}`);

export const addProductToWishList = (productId) =>
  apiConfig.client.post(`/products/${productId}/wishlist`);

export const addProductToCart = (productId, payload) =>
  apiConfig.client.post(`/products/${productId}/add-cart`, payload);

export const getCartProduct = () =>
  apiConfig.client.get(`/products/cart/list`);

export const getWishListProduct = () =>
  apiConfig.client.get(`/products/wishlist/list`);

export const deleteProductCart = (productId) =>
  apiConfig.client.delete(`/products/${productId}/cart`);

export const deleteProductFromWishlist = (productId) =>
  apiConfig.client.delete(`/products/${productId}/wishlist`);

export const updateOrderStatus = (orderId, payload) =>
  apiConfig.client.put(`/products/update/order-status/${orderId}`, payload);

export const updateProductCarts = (payload) =>
  apiConfig.client.put(`/products/carts`, payload);

export const orderHistory = () =>
  apiConfig.client.get(`/products/order/history`);

export const downloadInvoice = (productId, orderId) =>
  apiConfig.client.get(`/products/${productId}/order/${orderId}`, null, {
    responseType: "blob",
  });

export const applyCoupon = (coupon) => apiConfig.client.get(`/products/coupons/apply?coupon=${coupon}`);

export const checkCoupon = (coupon) => apiConfig.client.get(`/products/coupons/check?coupon=${coupon}`);
